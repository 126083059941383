import React from "react";
import { Card, CardHeader, Row, Col } from "shards-react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import UtilsCallBe from '../../utils/utilCallBe'

const Checkout = ({ profile,keycloak }) => {

  const createOrder=(data, actions)=> {
    return actions.order.create({
      purchase_units: [
        {
          reference_id: "shopbot-year",
          amount: {
            currency: "EUR",
            value: "0.10",
          },
        },
      ],
    });
  }

  const onApprove=async (data, actions)=> {
    console.log(data);
    console.log(actions);
    let url = window.env.BACKEND_URL + "chats/" + profile.attributes.tg_chat_id[0] + "/payments";
    await UtilsCallBe.callBeWithFetch(url, 'POST', data, keycloak.token)
    return actions.order.capture();
  }

  return (
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          {/*<img
            className="rounded-circle"
            src={user.picture}
            alt={user.name}
            width="110"
          />*/}
        </div>
        <h5 className="mb-0">Abbonati con tariffa annuale</h5>
        <br />

        <span className="text-muted d-block mb-2">Costo annuale:</span>
        <h5 className="mb-0">10,33 €</h5>
        <br />

        <Row>
          <PayPalScriptProvider
            options={{
              "client-id":
                "Af54W6f_EokBqQQAJseo8Jy72ykXUptM0W_AaNOncl-K4hmeqRAwDon-IciJjTIMHL8xHLRTyZSZY8HQ"
            }}
          >
            <Col></Col>
            <Col>
              <PayPalButtons
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
              />
            </Col>
            <Col></Col>
          </PayPalScriptProvider>
        </Row>
      </CardHeader>
    </Card>
  );
};
export default Checkout;
