/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, CardHeader} from "shards-react";

import { useHistory } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';


const MachinesView = ({ keycloak }) => {
  const [machinesView, setMachinesView] = useState({
    machines: [],
    devices:[]
  })


  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);

        let url = window.env.BACKEND_URL + "machines";
        let machinesFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)


        if (machinesFromBe.data != null) {
          setMachinesView({
            ...machinesView,
            machines: machinesFromBe.data.machines,
            devices: machinesFromBe.data.devices
          })
        }
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  if (machinesView.machines.lenght == 0) {
    return <div>Loading...</div>;
  }
  return (
    <Container fluid className="main-content-container px-8">

      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle="Macchine e Devices" className="text-sm-left" />
          </Col>
        </Row>


        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row>
            <Col>
              <Card large className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Macchine Monitorate</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          ID
                        </th>
                        <th scope="col" className="border-0">
                          Nome
                        </th>
                        <th scope="col" className="border-0">
                          Descrizione
                        </th>
                        <th scope="col" className="border-0">
                          Devices IDs
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        machinesView.machines.map((entry, index) => {
                          return (
                            <tr key={index}>
                              <td>{entry.id}</td>
                              <td>{entry.name}</td>
                              <td>{entry.description}</td>
                              <td>{entry.devices}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
              <Card large className="mb-4">
                <CardHeader className="border-bottom">
                  <h6 className="m-0">Devices</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          ID
                        </th>
                        <th scope="col" className="border-0">
                          Tipo
                        </th>
                        <th scope="col" className="border-0">
                          ID Macchina
                        </th>
                        <th scope="col" className="border-0">
                          Sensori
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        machinesView.devices.map((entry, index) => {
                          return (
                            <tr key={index}>
                              <td>{entry.id}</td>
                              <td>{entry.type}</td>
                              <td>{entry.machine_id}</td>
                              <td>{entry.sensors} </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>

            </Col>
          </Row>
        </Container>
      </Container>
    </Container>

  );

}

export default MachinesView;
