import React, { useState, useEffect } from "react";
import UtilsCallBe from '../utils/utilCallBe';
import PageTitle from "../components/common/PageTitle";
import {
  Container, Button, Row, Col, InputGroup,
  InputGroupAddon, InputGroupText, FormInput, FormTextarea
} from "shards-react";


const PlaygroundView = ({ keycloak }) => {
  const [playgroundView, setPlaygroundView] = useState({
    vars: null,
    anomaly_response:"",
    request:{}
  });

  useEffect(() => {
    (async () => {
      try {

        await keycloak.updateToken(30)
        let url = window.env.BACKEND_URL + "input-vars"
        let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

        setPlaygroundView({
          ...playgroundView,
          vars: responseFromBe.data.vars,
        })

      } catch (error) {
        console.log("Error while trying to retrieve data from backend")
        console.error(error)
      }
    })();
  }, []);


  const onChangeFormInput = (event, element) => {
    let new_request=playgroundView.request;
    new_request[element.var_name]=event.target.value
    setPlaygroundView({
      ...playgroundView,
      request: new_request,
    })
  }


  const sendRequest = async (event) => {
    await keycloak.updateToken(30)    
    setPlaygroundView({
      ...playgroundView,
      anomaly_response: ""
    })
    let request_obj=playgroundView.request;
    request_obj["model_id"]="model_001"
    let url = window.env.BACKEND_URL + "run-inference";
    let response_from_be = await UtilsCallBe.callBeWithFetch(url, 'POST', keycloak.token, request_obj)
    
    let anomaly_response = "Errore nella generazione della risposta"
    if (response_from_be.error == null) {
      let anomaly_outcome=response_from_be.data.anomaly_outcome;
      let anomaly_score=response_from_be.data.anomaly_score;
      if (anomaly_outcome == 1){
        anomaly_response = "Osservazione ANOMALA, "
      }else{
        anomaly_response = "Osservazione Normale, "
      }
      anomaly_response=anomaly_response+"con uno score di "+anomaly_score
    }
    setPlaygroundView({
      ...playgroundView,
      anomaly_response: anomaly_response
    })

  }



  if (!playgroundView.vars) {
    return <div>Loading...</div>;
  } else
    return (
      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle="Test models" className="text-sm-left" />
          </Col>
        </Row>

        <Container fluid className="main-content-container px-4">
          <label>Inserisci i dati da sottoporre al modello</label>
          <Row>
            <Col lg="6" md="6" sm="12" className="mb-12">
              <Row>
                {playgroundView.vars.map((element, index) => {
                  return (
                    <Row key={index}>
                      <InputGroup >
                        <InputGroupAddon
                          type="prepend"
                          style={{ "minWidth": "250px" }}
                        >
                          <InputGroupText
                            style={{ "minInlineSize": "-webkit-fill-available" }}
                          >{element.var_full_name + " (" + element.unit + ")"}</InputGroupText>
                        </InputGroupAddon>
                        <FormInput
                          placeholder="inserisci il valore" onChange={(event) => onChangeFormInput(event, element)}
                          style={{ "maxWidth": "250px" }}
                        />
                      </InputGroup>
                      <p></p>
                    </Row>
                  )
                })}
              </Row>
              <p></p>
              <Row>
                <Button
                  onClick={sendRequest}
                  theme="info"
                  className="mb-2 mr-1">
                  Invia
                </Button>
              </Row>
            </Col>

          </Row>
          <label>{playgroundView.anomaly_response}</label>
        </Container>
      </Container>

    )
};

export default PlaygroundView;
