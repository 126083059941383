import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
} from "shards-react";

import UtilsCallBe from '../../utils/utilCallBe'

const UserDetails = ({ profile, keycloak }) => {

  const [userDetailsState, setUserDetailsState] = useState({
    userDetails: null
  })
  function pad(s) { return (s < 10) ? '0' + s : s; }
  
  const calculateEndDate = (userDetails) => {
    let date=userDetails.created_at;
    let dayToAdd=90;
    let endDateLabel="Termine periodo di prova:";
    if (userDetails.subscriptions != null){
      date=userDetails.created_at;
      dayToAdd=365;
      endDateLabel="Termine Licenza:";
    }
    console.log(date);
    let endDate = new Date(date)
    endDate.setDate(endDate.getDate() + dayToAdd);
    console.log(endDate);
    return{
      endDate: [pad(endDate.getDate()), pad(endDate.getMonth() + 1), endDate.getFullYear()].join('/'),
      endDateLabel:endDateLabel
    }

  }


  useEffect(() => {
    (async () => {
      try {


        //retrieve report configurations
        let url = window.env.BACKEND_URL + "chats/" + profile.attributes.tg_chat_id[0] + "/user-details";
        let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', null, keycloak.token)
        let endDateObj=calculateEndDate(responseFromBe.data.data[0])
        setUserDetailsState({
          ...userDetailsState,
          userDetails:responseFromBe.data.data[0] ,
          endDate:endDateObj.endDate,
          endDateLabel:endDateObj.endDateLabel

        })


      } catch (error) {
        console.error(error)
      }
    })();
  }, []);

  if (userDetailsState.userDetails == null) {
    return "Loading..."
  }
  return (

    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          {/*<img
            className="rounded-circle"
            src={user.picture}
            alt={user.name}
            width="110"
          />*/}
        </div>
        <span className="text-muted d-block mb-2" >Username:</span>
        <h4 className="mb-0">{
          profile.username
        }</h4>
        <br />
        <span className="text-muted d-block mb-2" >Licenza Account:</span>
        <h5 className="mb-0">{
          profile.attributes.licence[0]
        }</h5>
        <br />
        <span className="text-muted d-block mb-2" >{userDetailsState.endDateLabel}:</span>
        <h5 className="mb-0">{
          userDetailsState.endDate
        }</h5>


      </CardHeader>
    </Card>
  );
}
export default UserDetails;
