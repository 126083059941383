import React, { useState, useEffect } from "react";
import UtilsCallBe from '../utils/utilCallBe';
import PageTitle from "../components/common/PageTitle";
import { Container, Button, Row, Col, FormSelect, Badge } from "shards-react";
import LineChart from "../components/charts/LineChart"
import BarChart from "../components/charts/BarChart";


const DashboardView = ({ keycloak }) => {
    const [dashboardView, setDashboardView] = useState({
        loading:true,
        start_time: "-5m",
        stop_time: "now",
        internal_chart_data: null,
        external_chart_data: null,
        door_chart_data: null,
        watt_chart_data: null,
        machines: [],
        selected_machine: null,
        num_point: 0,
        last_watt: 0,
        last_ti: 0,
        last_hi: 0,
        last_te: 0,
        last_he: 0,
        aggregation_rate: "1s",                                                                        
        vars: [],        
        watt_th: 0,           
        ti_th: 0,            
        hi_th: 0,            
        te_th: 0,            
        he_th: 0,
        n_watt_over_th:0,
        n_ti_over_th:0,
        n_hi_over_th:0,
        n_te_over_th:0,
        n_he_over_th:0,
    })

    const get_machines = async () => {
        try {
            await keycloak.updateToken(30);
            let url = window.env.BACKEND_URL + "machines";
            let machines_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
            if (machines_response.data != null) {
                return machines_response.data.machines
            }
            else {
                return []
            }
        } catch (error) {
            console.error(error)
            return []
        }

    }

    const get_internal_chart_data = async (start_time, selected_machine, aggregation_rate) => {
        try {
            await keycloak.updateToken(30);

            let var_names = ["ti", "hi"]
            let url = window.env.BACKEND_URL + "data/multi-vars?var_names=" + var_names + "&start_time=" + start_time + "&machine_id=" + selected_machine + "&aggregation_rate=" + aggregation_rate + "&aggregation_fn=mean";
            let data_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
            let ts = [];
            let ti = [];
            let hi = [];
            let ti_over_th = [];
            let hi_over_th = [];
            if (data_response.data != null) {
                ts = data_response.data.query_result.ts;
                ti = data_response.data.query_result.ti;
                hi = data_response.data.query_result.hi;
                ti_over_th = data_response.data.query_result.ti_over_th;
                hi_over_th = data_response.data.query_result.hi_over_th;
            }

            let ti_pointBackgroundColor = []
            ti_over_th.forEach(point => {
                if (point == 1) {
                    ti_pointBackgroundColor.push("#ff0000")
                } else {
                    ti_pointBackgroundColor.push("#00ff00")
                }
            });

            let hi_pointBackgroundColor = []
            hi_over_th.forEach(point => {
                if (point == 1) {
                    hi_pointBackgroundColor.push("#ff0000")
                } else {
                    hi_pointBackgroundColor.push("#00ff00")
                }
            });


            const chartData = {
                labels: ts,
                datasets: [
                    {
                        label: "Temperatura interna",
                        fill: "start",
                        data: ti,
                        backgroundColor: "rgba(0,123,255,0.1)",
                        borderColor: "rgba(0,123,255,1)",
                        pointBackgroundColor: ti_pointBackgroundColor,
                        pointHoverBackgroundColor: "rgb(0,123,255)",
                        borderWidth: 1.5,
                        pointRadius: 4,
                        pointHoverRadius: 6
                    },
                    {
                        label: "Umidità interna",
                        fill: "start",
                        data: hi,
                        backgroundColor: "rgba(123,0,255,0.1)",
                        borderColor: "rgba(123,0,255,1)",
                        pointBackgroundColor: hi_pointBackgroundColor,
                        pointHoverBackgroundColor: "rgb(123,0,255)",
                        borderWidth: 1.5,
                        pointRadius: 4,
                        pointHoverRadius: 6
                    }
                ]
            }
            return chartData

        } catch (error) {
            console.error(error)
            return {
                labels: [],
                datasets: [{
                    label: "NO DATA"
                }]
            }
        }
    }

    const get_external_chart_data = async (start_time, selected_machine, aggregation_rate) => {
        try {
            await keycloak.updateToken(30);

            let var_names = ["te", "he"]

            let url = window.env.BACKEND_URL + "data/multi-vars?var_names=" + var_names + "&start_time=" + start_time + "&machine_id=" + selected_machine + "&aggregation_rate=" + aggregation_rate + "&aggregation_fn=mean";
            let data_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
            let ts = [];
            let te = [];
            let he = [];
            let te_over_th = [];
            let he_over_th = [];

            if (data_response.data != null) {
                ts = data_response.data.query_result.ts;
                te = data_response.data.query_result.te;
                he = data_response.data.query_result.he
                te_over_th = data_response.data.query_result.te_over_th;
                he_over_th = data_response.data.query_result.he_over_th;
            }
            let te_pointBackgroundColor = []
            te_over_th.forEach(point => {
                if (point == 1) {
                    te_pointBackgroundColor.push("#ff0000")
                } else {
                    te_pointBackgroundColor.push("#00ff00")
                }
            });

            let he_pointBackgroundColor = []
            he_over_th.forEach(point => {
                if (point == 1) {
                    he_pointBackgroundColor.push("#ff0000")
                } else {
                    he_pointBackgroundColor.push("#00ff00")
                }
            });


            const chartData = {
                labels: ts,
                datasets: [
                    {
                        label: "Temperatura esterna",
                        fill: "start",
                        data: te,
                        backgroundColor: "rgba(0,123,255,0.1)",
                        borderColor: "rgba(0,123,255,1)",
                        pointBackgroundColor: te_pointBackgroundColor,
                        pointHoverBackgroundColor: "rgb(0,123,255)",
                        borderWidth: 1.5,
                        pointRadius: 4,
                        pointHoverRadius: 6
                    },
                    {
                        label: "Umidità esterna",
                        fill: "start",
                        data: he,
                        backgroundColor: "rgba(123,0,255,0.1)",
                        borderColor: "rgba(123,0,255,1)",
                        pointBackgroundColor: he_pointBackgroundColor,
                        pointHoverBackgroundColor: "rgb(123,0,255)",
                        borderWidth: 1.5,
                        pointRadius: 4,
                        pointHoverRadius: 6
                    }
                ]
            }
            return chartData
        } catch (error) {
            console.error(error)
            return {
                labels: [],
                datasets: [{
                    label: "NO DATA"
                }]
            }
        }
    }

    const get_watt_chart_data = async (start_time, selected_machine, aggregation_rate) => {
        try {
            await keycloak.updateToken(30);
            let url = window.env.BACKEND_URL + "data/multi-vars?var_names=w&start_time=" + start_time + "&machine_id=" + selected_machine + "&aggregation_rate=" + aggregation_rate + "&aggregation_fn=mean";
            let data_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
            let ts = [];
            let w = [];
            let w_over_th = [];
            if (data_response.data != null) {
                ts = data_response.data.query_result.ts;
                w = data_response.data.query_result.w;
                w_over_th = data_response.data.query_result.w_over_th;
            }

            let pointBackgroundColor = []
            w_over_th.forEach(point => {
                if (point == 1) {
                    pointBackgroundColor.push("#ff0000")
                } else {
                    pointBackgroundColor.push("#00ff00")
                }
            });

            const chartData = {
                labels: ts,
                datasets: [
                    {
                        label: "Consumo energetico",
                        fill: "start",
                        data: w,
                        backgroundColor: "rgba(0,123,255,0.1)",
                        borderColor: "rgba(0,123,255,1)",
                        pointBackgroundColor: pointBackgroundColor,
                        pointHoverBackgroundColor: "rgb(0,123,255)",
                        borderWidth: 1.5,
                        pointRadius: 4,
                        pointHoverRadius: 6
                    }
                ]
            }
            return chartData
        } catch (error) {
            console.error(error)
            return {
                labels: [],
                datasets: [{
                    label: "NO DATA"
                }]
            }
        }
    }

    const get_door_chart_data = async (start_time, selected_machine) => {
        try {
            await keycloak.updateToken(30);
            let url = window.env.BACKEND_URL + "data/single-var?var_name=d&start_time=" + start_time + "&machine_id=" + selected_machine + "&aggregation_rate=1s&aggregation_fn=mean";
            let data_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
            let ts = [];
            let d = [];
            let color = [];
            if (data_response.data != null) {
                ts = data_response.data.query_result.ts;
                d = data_response.data.query_result.d;
                data_response.data.query_result.d.forEach(element => {
                    color.push("rgb(13,225,128)")
                });

            }
            const chartData = {
                labels: ts,
                datasets: [
                    {
                        label: "Storico Apertura Porta",
                        fill: "start",
                        data: d,
                        backgroundColor: color,
                        borderColor: color,
                        borderWidth: 1.5
                    }
                ]
            }
            return chartData
        } catch (error) {
            console.error(error)
            return {
                labels: [],
                datasets: [{
                    label: "NO DATA"
                }]
            }
        }
    }

    const get_input_vars = async () => {
        try {
            await keycloak.updateToken(30);
            let url = window.env.BACKEND_URL + "input-vars";
            let data_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
            if (data_response.data != null) {
                return data_response.data.vars;
            }
        } catch (error) {
            console.error(error)
            return []
        }
    }

    const create_chart_subtile = (var_name) => {
        if (var_name == "w") {
            return " Soglia impostata in Watt: "+dashboardView.watt_th+ " | Punti oltre soglia: "+dashboardView.n_watt_over_th
        }
        if (var_name == "interno") {
            return " Soglia impostata Temperatura: "+dashboardView.ti_th+ " | Punti oltre soglia: "+dashboardView.n_ti_over_th+
            " |   Soglia impostata Umidità: "+dashboardView.hi_th+ " | Punti oltre soglia: "+dashboardView.n_hi_over_th
        }
        if (var_name == "esterno") {
            return " Soglia impostata Temperatura: "+dashboardView.te_th+ " | Punti oltre soglia: "+dashboardView.n_te_over_th+
            " |   Soglia impostata Umidità: "+dashboardView.he_th+ " | Punti oltre soglia: "+dashboardView.n_he_over_th
        }
    };



    const get_data = async (start_time) => {
        let machines = await get_machines();
        let selected_machine;

        if (dashboardView.selected_machine == null && machines.length > 0) {
            selected_machine = machines[0].id;
        }
        else {
            selected_machine = dashboardView.selected_machine
        }
        let vars = await get_input_vars()

        let watt_chart_data = await get_watt_chart_data(start_time, selected_machine, dashboardView.aggregation_rate);
        let internal_chart_data = await get_internal_chart_data(start_time, selected_machine, dashboardView.aggregation_rate)
        let external_chart_data = await get_external_chart_data(start_time, selected_machine, dashboardView.aggregation_rate)
        let door_chart_data = await get_door_chart_data(start_time, selected_machine, dashboardView.aggregation_rate)

        let num_point = null
        let last_watt = null
        let last_ti = null
        let last_hi = null
        let last_te = null
        let last_he = null
        
        let watt_th = null;        
        let ti_th = null       
        let hi_th = null       
        let te_th = null       
        let he_th = null
        let n_watt_over_th=null;
        let n_ti_over_th;
        let n_hi_over_th;
        let n_te_over_th;
        let n_he_over_th;

       
        if (internal_chart_data.labels.length > 0
            && external_chart_data.labels.length > 0
            && watt_chart_data.labels.length > 0
            && door_chart_data.labels.length > 0) {

            num_point = watt_chart_data.datasets[0].data.length;
            last_watt = watt_chart_data.datasets[0].data[num_point - 1]
            last_ti = internal_chart_data.datasets[0].data[num_point - 1]
            last_hi = internal_chart_data.datasets[1].data[num_point - 1]
            last_te = external_chart_data.datasets[0].data[num_point - 1]
            last_he = external_chart_data.datasets[1].data[num_point - 1]

            vars.forEach(var_dict => {
                if (var_dict.var_name == "w") {
                    watt_th = var_dict.threshold
                    n_watt_over_th = watt_chart_data.datasets[0].data.filter(x => x > watt_th).length
                }
                if (var_dict.var_name == "ti") {
                    ti_th = var_dict.threshold
                    n_ti_over_th = internal_chart_data.datasets[0].data.filter(x => x > ti_th).length      
                }
                if (var_dict.var_name == "hi") {
                    hi_th = var_dict.threshold
                    n_hi_over_th = internal_chart_data.datasets[1].data.filter(x => x > hi_th).length         
                }
                if (var_dict.var_name == "te") {
                    te_th = var_dict.threshold
                    n_te_over_th = external_chart_data.datasets[0].data.filter(x => x > te_th).length
                }
                if (var_dict.var_name == "he") {
                    he_th = var_dict.threshold
                    n_he_over_th = external_chart_data.datasets[1].data.filter(x => x > he_th).length
                }
            })
        }

        setDashboardView({
            ...dashboardView,
            loading:false,
            start_time: start_time,
            machines: machines,
            selected_machine: selected_machine,
            watt_chart_data: watt_chart_data,
            internal_chart_data: internal_chart_data,
            external_chart_data: external_chart_data,
            door_chart_data: door_chart_data,
            num_point: num_point,
            last_watt: last_watt,
            last_ti: last_ti,
            last_hi: last_hi,
            last_te: last_te,
            last_he: last_he,
            vars: vars,
            watt_th: watt_th,            
            ti_th: ti_th,            
            hi_th: hi_th,            
            te_th: te_th,            
            he_th: he_th,
            n_watt_over_th:n_watt_over_th,
            n_ti_over_th:n_ti_over_th,
            n_hi_over_th:n_hi_over_th,
            n_te_over_th:n_te_over_th,
            n_he_over_th:n_he_over_th

        })

    }

    const handleStartTimeChange = async (event) => {
        let start_time = event.target.value
        await setDashboardView({
            ...dashboardView,
            start_time: event.target.value
        })
    }

    const handleMachineChange = async (event) => {
        await setDashboardView({
            ...dashboardView,
            selected_machine: event.target.value
        })
    }


    const handleAggregationRateTimeChange = async (event) => {
        await setDashboardView({
            ...dashboardView,
            aggregation_rate: event.target.value
        })

    }


    useEffect(() => {
        (async () => {
            await get_machines();
            await get_data("-5m");
        })();
    }, []);


    if (!dashboardView.internal_chart_data
        && !dashboardView.external_chart_data
        && !dashboardView.watt_chart_data
        && !dashboardView.door_chart_data
        && !dashboardView.selected_machine
        && dashboardView.machines.length == 0) {
        return <div>Loading...</div>;
    } else
        return (
            <Container fluid className="main-content-container px-8" >
                {/* Page Header */}
                <Row className="page-header py-4" >
                    <Col>
                        <PageTitle sm="4" title="" subtitle="Dashboard Overview" className="text-sm-left" />
                    </Col>
                </Row>


                <Container fluid className="main-content-container px-2">
                    <Row noGutters>
                        <Col lg="3" md="6" sm="12" className="mb-8">Intervallo tempo</Col>
                        <Col lg="3" md="6" sm="12" className="mb-8">Macchina</Col>
                        <Col lg="3" md="6" sm="12" className="mb-8">Aggregazione dati</Col>
                    </Row>
                    <Row noGutters>
                        <Col lg="3" md="6" sm="12" className="mb-8">
                            <FormSelect
                                style={{ "minWidth": "184px", "maxHeight": "48px" }}
                                onChange={handleStartTimeChange}>
                                <option selected value="-5m">Ultimi 5 minuti</option>
                                <option value="-30m">Ultima mezz'ora</option>
                                <option value="-1h">Ultima ora</option>
                                <option value="-6h">Ultime 6 ore</option>
                                <option value="-12h">Ultime 12 ore</option>
                                <option value="-24h">Ultime 24 ore</option>
                            </FormSelect>
                        </Col>
                        <Col lg="3" md="6" sm="12" className="mb-8">
                            <FormSelect
                                style={{ "minWidth": "184px", "maxHeight": "48px" }}
                                onChange={handleMachineChange}>
                                {dashboardView.machines.map(machine => {
                                    return <option value={machine.id}>{machine.name}</option>
                                })}

                            </FormSelect>
                        </Col>
                        <Col lg="3" md="6" sm="12" className="mb-8">
                            <FormSelect
                                style={{ "minWidth": "184px", "maxHeight": "48px" }}
                                onChange={handleAggregationRateTimeChange}>
                                <option selected value="1s">No</option>
                                <option value="1m">1 minuto</option>
                                <option value="10m">10 Minuti</option>
                                <option value="30m">30 Minuti</option>
                                <option value="1h">1 ora</option>
                                <option value="6h">6 ore</option>
                            </FormSelect>
                        </Col>
                        <Col lg="3" md="6" sm="12" className="mb-8">
                            <Button onClick={() => {setDashboardView({...dashboardView,loading:true}); get_data(dashboardView.start_time)}}>
                                Aggiorna
                            </Button>
                        </Col>
                        <Col lg="3" md="6" sm="12" className="mb-8">
                            {dashboardView.loading?"Loading...":""}
                        </Col>
                    </Row>
                    <p></p>
                    <Container fluid className="main-content-container px-2">
                        <Row>
                            <Col lg="2" md="6" sm="12" className="mb-8">
                                <Badge
                                    theme="secondary" style={{ "minHeight": "32px", "minWidth": "64px" }}>
                                    Punti: {dashboardView.num_point}
                                </Badge>

                            </Col>

                            <Col lg="2" md="6" sm="12" className="mb-8">
                                <Badge
                                    theme="info" style={{ "minHeight": "32px", "minWidth": "128px" }}>
                                    Consumo Energetico: {dashboardView.last_watt}
                                </Badge>
                            </Col>

                            <Col lg="2" md="6" sm="12" className="mb-8">
                                <Badge
                                    theme="info" style={{ "minHeight": "32px", "minWidth": "128px" }}>
                                    Temperatura Intena: {dashboardView.last_ti}
                                </Badge>
                            </Col>

                            <Col lg="2" md="6" sm="12" className="mb-8">
                                <Badge
                                    theme="info" style={{ "minHeight": "32px", "minWidth": "128px" }}>
                                    Umidita interna: {dashboardView.last_hi}
                                </Badge>
                            </Col>

                            <Col lg="2" md="6" sm="12" className="mb-8">
                                <Badge
                                    theme="info" style={{ "minHeight": "32px", "minWidth": "128px" }}>
                                    Temepratura Esterna: {dashboardView.last_te}
                                </Badge>
                            </Col>

                            <Col lg="2" md="6" sm="12" className="mb-8">
                                <Badge
                                    theme="info" style={{ "minHeight": "32px", "minWidth": "128px", "text-align": "center", "vertical-align": "middle" }}>
                                    Umidità esterna {dashboardView.last_he}
                                </Badge>
                            </Col>

                            <Col lg="2" md="6" sm="12" className="mb-8"></Col>
                            <Col lg="2" md="6" sm="12" className="mb-8"></Col>

                        </Row>
                    </Container>
                </Container>

                <p> </p>
                <Container fluid className="main-content-container px-12">
                    <Row>
                        <Col lg="12" md="6" sm="6" className="mb-4">
                            <LineChart title="Consumo Energetico" chartData={dashboardView.watt_chart_data} subTitle={create_chart_subtile("w")} />
                        </Col>
                    </Row>
                </Container>
                <p> </p>
                <Container fluid className="main-content-container px-12">
                    <Row>
                        <Col lg="12" md="6" sm="12" className="mb-8">
                            <LineChart title="Stato Interno" chartData={dashboardView.internal_chart_data} subTitle={create_chart_subtile("interno")}/>
                        </Col>
                    </Row>
                </Container>
                <p> </p>
                <Container fluid className="main-content-container px-12">
                    <Row>
                        <Col lg="12" md="6" sm="12" className="mb-8">
                            <LineChart title="Stato Esterno" chartData={dashboardView.external_chart_data} subTitle={create_chart_subtile("esterno")} />
                        </Col>
                    </Row>
                </Container>
                <p> </p>
                <Container fluid className="main-content-container px-12">
                    <Row>
                        <Col lg="12" md="6" sm="12" className="mb-8">
                            <BarChart title="Storico Porta" chartData={dashboardView.door_chart_data} />
                        </Col>
                    </Row>
                </Container>




            </Container>

        )
};

export default DashboardView;
