import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader,
  ModalBody, FormSelect, ListGroupItem, ListGroup,Badge
} from "shards-react";
import { useHistory } from "react-router-dom";

import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';
import PieChart from "../components/charts/PieChart";
import BarChart from "../components/charts/BarChart";

import LineChart from "../components/charts/LineChart";


const AnomalyInfoView = ({ keycloak }) => {
  const [anomalyView, setAnomalyView] = useState({
    selected_machine: null,
    machines: [],
    anomaly_details: [],
    start_time: null,
    anomaly_pie_chart_data: null,
    anomaly_cnt: null,
    anomaly_per: null,
    normal_cnt: null,
    normal_per: null,
    total_cnt: null,
    anomaly_points: null,
    anomaly_line_chart_data: null,
    selected_row: null,
    aggregation_rate: "1m",
    open_modal: false,
    loading: false
  })

  let history = useHistory();

  const get_anomaly_data = async (start_time, selected_machine, aggregation_rate) => {
    try {
      await keycloak.updateToken(30);
      let url = window.env.BACKEND_URL + "anomalies?start_time=" + start_time + "&machine_id=" + selected_machine + "&aggregation_rate=" + aggregation_rate;
      let data_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

      if (data_response.data != null) {
        return data_response.data
      }
    }
    catch (error) {
      console.error(error)
      return null
    }
  }

  const get_data = async (start_time) => {
    let machines = await get_machines();
    let selected_machine;

    if (anomalyView.selected_machine == null && machines.length > 0) {
      selected_machine = machines[0].id;
    }
    else {
      selected_machine = anomalyView.selected_machine
    }

    let aggregation_rate = anomalyView.aggregation_rate

    let anomaly_data = await get_anomaly_data(start_time, selected_machine, aggregation_rate)
    let pieChartData;
    let lineChartData;
    let anomaly_cnt = "NO DATA";
    let anomaly_per = "NO DATA"
    let normal_cnt = "NO DATA";
    let normal_per = "NO DATA";
    let total_cnt = "NO DATA";
    let anomaly_points = [];
    let anomaly_details = [];

    if (anomaly_data == null) {
      pieChartData = {
        datasets: [],
        labels: ["NO DATA"]
      }
      lineChartData = {
        datasets: [{
          label: "NO DATA"
        }],
        labels: []
      }
    } else {
      pieChartData = {
        datasets: [
          {
            hoverBorderColor: "#ffffff",
            data: [anomaly_data.anomaly_per, anomaly_data.normal_per],
            backgroundColor: [
              "rgba(224,24,24,0.9)",
              "rgba(24,224,24,0.9)",
            ]
          }
        ],
        labels: ["Anomalie", "Normali"]
      }

      lineChartData = {
        labels: anomaly_data.points.ts,
        datasets: [
          {
            label: "Anomalie",
            fill: "start",
            data: anomaly_data.points.anomaly_outcome,
            backgroundColor: "rgba(0,123,255,0.1)",
            borderColor: "rgba(0,123,255,1)",
            pointBackgroundColor: "#ffffff",
            pointHoverBackgroundColor: "rgb(0,123,255)",
            borderWidth: 1.5,
            pointRadius: 1,
            pointHoverRadius: 1
          },
          {
            label: "Score",
            fill: "start",
            data: anomaly_data.points.anomaly_score,
            backgroundColor: "rgba(123,0,255,0.1)",
            borderColor: "rgba(123,0,255,1)",
            pointBackgroundColor: "#ffffff",
            pointHoverBackgroundColor: "rgb(123,0,255)",
            borderWidth: 1.5,
            pointRadius: 1,
            pointHoverRadius: 1
          }
        ]
      }

      anomaly_cnt = anomaly_data.anomaly_cnt;
      anomaly_per = anomaly_data.anomaly_per;
      normal_cnt = anomaly_data.normal_cnt;
      normal_per = anomaly_data.normal_per;
      total_cnt = anomaly_data.total_cnt;
      anomaly_points = anomaly_data.points;
      anomaly_details = anomaly_data.anomaly_details;

    }

    setAnomalyView({
      ...anomalyView,
      machines: machines,
      start_time: start_time,
      anomaly_pie_chart_data: pieChartData,
      anomaly_line_chart_data: lineChartData,
      anomaly_cnt: anomaly_cnt,
      anomaly_per: anomaly_per,
      normal_cnt: normal_cnt,
      normal_per: normal_per,
      total_cnt: total_cnt,
      anomaly_points: anomaly_points,
      anomaly_details: anomaly_details,
      selected_row: null
    })
  }

  const toggleAnomalyDetail = () => {
    setAnomalyView({
      ...anomalyView, open_modal: false, selected_row: null
    })
  }

  const handleStartTimeChange = async (event) => {
    await setAnomalyView({
      ...anomalyView,
      start_time: event.target.value
    })

  }

  const handleMachineChange = async (event) => {
    await setAnomalyView({
      ...anomalyView,
      selected_machine: event.target.value
    })
  }

  const open_anomaly_detail = (entry) => {
    console.log()
    let start_time = entry.start_time
    let end_time = entry.end_time
    //history.push("document-list?aggregation_rate="+aggregation_rate+"&end_time="+end_time)
  }

  const get_machines = async () => {
    try {
      await keycloak.updateToken(30);
      let url = window.env.BACKEND_URL + "machines";
      let machines_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
      if (machines_response.data != null) {
        return machines_response.data.machines
      }
      else {
        return []
      }
    }
    catch (error) {
      console.error(error)
      return []
    }
  }

  const handleAggregationRateTimeChange = async (event) => {
    await setAnomalyView({
      ...anomalyView,
      aggregation_rate: event.target.value
    })

  }

  useEffect(() => {
    (async () => {
      try {
        await get_data("-5m")
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);
  if (!anomalyView.anomaly_pie_chart_data || !anomalyView.anomaly_line_chart_data) {
    return <div>Loading...</div>;
  }
  return (
    <Container fluid className="main-content-container px-8">
      <Modal
        open={anomalyView.open_modal}
        toggle={toggleAnomalyDetail}
        size="lg"
        style={{ "minWidth": "1200px", "minWidth": "600px" }}
      >
        <ModalHeader style={{ "minWidth": "1200px", "minWidth": "600px" }}>Dettaglio Minuto: {!anomalyView.selected_row ? "" : anomalyView.selected_row.minute}</ModalHeader>
        <ModalBody style={{ "minWidth": "1200px", "minHeight": "600px" }}>
          <LineChart title="Anomaly score" chartData={anomalyView.anomaly_line_chart_data} />
        </ModalBody>
      </Modal>

      <Row noGutters className="page-header py-4" >
        <Col>
          <PageTitle sm="2" title="" subtitle="Dettaglio anomalie" className="text-sm-left" />
        </Col>
      </Row>

      <Container fluid className="main-content-container px-2">
        <Row noGutters>
          <Col lg="3" md="6" sm="12" className="mb-8">Intervallo tempo</Col>
          <Col lg="3" md="6" sm="12" className="mb-8">Macchina</Col>
          <Col lg="3" md="6" sm="12" className="mb-8">Aggregazione dati</Col>
        </Row>
        <Row noGutters>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <FormSelect
              style={{ "minWidth": "184px", "maxHeight": "48px" }}
              onChange={handleStartTimeChange}>
              <option selected value="-5m">Ultimi 5 minuti</option>
              <option value="-30m">Ultima mezz'ora</option>
              <option value="-1h">Ultima ora</option>
              <option value="-6h">Ultime 6 ore</option>
              <option value="-12h">Ultime 12 ore</option>
              <option value="-24h">Ultime 24 ore</option>
            </FormSelect>
          </Col>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <FormSelect
              style={{ "minWidth": "184px", "maxHeight": "48px" }}
              onChange={handleMachineChange}>
              {anomalyView.machines.map(machine => {
                return <option value={machine.id}>{machine.name}</option>
              })}
            </FormSelect>
          </Col>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <FormSelect
              style={{ "minWidth": "184px", "maxHeight": "48px" }}
              onChange={handleAggregationRateTimeChange}>
              <option selected value="1m">1m</option>
              <option value="10m">10 Minuti</option>
              <option value="30m">30 Minuti</option>
              <option value="1h">1 ora</option>
              <option value="6h">6 ore</option>
            </FormSelect>
          </Col>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <Button
              onClick={() => { setAnomalyView({ ...anomalyView, loading: true }); get_data(anomalyView.start_time) }}>Aggiorna
            </Button>
          </Col>
          <Col lg="3" md="6" sm="12" className="mb-8">
            {anomalyView.loading ? "Loading..." : ""}
          </Col>
        </Row>
        <p></p>
        <Row>
          <Col lg="3" md="6" sm="12" className="mb-8">
            <Badge
              theme="secondary" style={{ "minHeight": "32px", "minWidth": "64px" }}>
              Numero Osservazioni: {anomalyView.total_cnt}
            </Badge>

          </Col>

          <Col lg="2" md="6" sm="12" className="mb-8">
            <Badge
              theme="danger" style={{ "minHeight": "32px", "minWidth": "128px" }}>
              Osservazioni Anomale: {anomalyView.anomaly_cnt}
            </Badge>
          </Col>

          <Col lg="2" md="6" sm="12" className="mb-8">
            <Badge
              theme="info" style={{ "minHeight": "32px", "minWidth": "128px" }}>
              Osservazioni Normali: {anomalyView.normal_cnt}
            </Badge>
          </Col>

          <Col lg="2" md="6" sm="12" className="mb-8">
            <Badge
              theme="danger" style={{ "minHeight": "32px", "minWidth": "128px" }}>
              Percentuale Anomalie: {anomalyView.anomaly_per}
            </Badge>
          </Col>

          <Col lg="2" md="6" sm="12" className="mb-8">
            <Badge
              theme="info" style={{ "minHeight": "32px", "minWidth": "128px" }}>
              Percentuale Normali: {anomalyView.normal_per}
            </Badge>
          </Col>

        </Row>
        <p></p>
        <Container></Container>
      </Container>

      <p> </p>
      <Container fluid className="main-content-container px-6">
        <Row style={{ "minHeight": "540px" }}>
          <Col lg="3" md="6" sm="12" className="mb-12">
            <PieChart title="Punti Anomali/Normali" chartData={anomalyView.anomaly_pie_chart_data} />
          </Col>
          <Col lg="9" md="6" sm="12" className="mb-8">
            <LineChart title="Anomaly score" chartData={anomalyView.anomaly_line_chart_data} />
          </Col>          
        </Row>
      </Container>
      

      <p></p>
      <Container fluid className="main-content-container px-12">
        <Row>
          <Col>
            <Card large className="mb-4">
              <CardBody className="p-0 pb-3">
                <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        Inizio intervallo
                      </th>
                      <th scope="col" className="border-0">
                        Fine intervallo
                      </th>
                      <th scope="col" className="border-0">
                        Media Anomaly Score
                      </th>
                      <th scope="col" className="border-0">
                        Osservazioni anomale
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      anomalyView.anomaly_details.map((entry, index) => {
                        return (
                          <tr key={index}>
                            <td>{entry.start_time}</td>
                            <td>{entry.end_time}</td>
                            <td>{entry.anomaly_score}</td>
                            <td>{entry.anomalies_count}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );

}

export default AnomalyInfoView;
