import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views


import DoLogout from "./views/DoLogout";
import ExportsView from "./views/ExportsView";
import SettingsView from "./views/SettingsView"
import LogsView from "./views/LogsView";
import AnomalyInfoView from "./views/AnomalyInfoView";
import DevicesView from "./views/DevicesView";
import MachinesView from "./views/MachinesView";
import DashboardView from "./views/DashboardView";
import VarListView from "./views/VarListView"
import PlaygroundView from "./views/PlaygroundView";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/dashboard",
    exact: true,
    layout: DefaultLayout,
    component: DashboardView
  },

  {
    path: "/machines",
    layout: DefaultLayout,
    component: MachinesView
  },
  {
    path: "/devices",
    layout: DefaultLayout,
    component: DevicesView
  },
  {
    path: "/vars",
    layout: DefaultLayout,
    component: VarListView
  },
  {
    path: "/anomaly-info",
    layout: DefaultLayout,
    component: AnomalyInfoView
  },  
  {
    path: "/logs",
    layout: DefaultLayout,
    component: LogsView
  },
  {
    path: "/exports",
    layout: DefaultLayout,
    component: ExportsView
  },
  {
    path: "/playground",
    layout: DefaultLayout,
    component: PlaygroundView
  },
  {
    path: "/settings",
    layout: DefaultLayout,
    component: SettingsView
  },
  {
    path: "/do-logout",
    layout: DefaultLayout,
    component: DoLogout
  }
];
