import React, { useRef, useEffect, useState } from "react";
import { Card, CardHeader, CardBody } from "shards-react";
import Chart from "../../utils/chart";

const LineChart = ({ title, chartData, subTitle }) => {
  const [lineChart, setLineChart] = useState({
    chart: null
  })

  const canvasRef = useRef()

  const chartOptions = {
    responsive: true,
    legend: {
      position: "top"
    },
    elements: {
      line: {
        // A higher value makes the line look skewed at this ratio.
        tension: 0.2
      },
      point: {
        radius: 150
      }
    },
    scales: {
      xAxes: [
        {
          gridLines: false,
          ticks: {
            callback(tick, index) {
              // Jump every 7 values on the X axis labels to avoid clutter.
              return index % 10 !== 0 ? "" : tick;
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            suggestedMax: 1,
            callback(tick) {
              if (tick === 0) {
                return tick;
              }
              // Format the amounts using Ks for thousands.
              return tick > 999 ? `${(tick / 1000).toFixed(1)}K` : tick;
            }
          }
        }
      ]
    },
    hover: {
      mode: "nearest",
      intersect: false
    },
    tooltips: {
      custom: false,
      mode: "nearest",
      intersect: false
    }
  }





  useEffect(() => {
    (async () => {
      try {
        if (lineChart.chart != null) {
          lineChart.chart.destroy();
        }
        let newchart = new Chart(canvasRef.current, {
          type: "LineWithLine",
          data: chartData,
          options: chartOptions
        });
        /*
        for (let i = 0; i < chartData.datasets.length; i++) {
          // They can still be triggered on hover.
          let buoMeta = newchart.getDatasetMeta(i);
          buoMeta.data.forEach(element => {
            element._model.radius = 4
          })
        }*/
        /*
        buoMeta.data[0]._model.radius = 10;
        buoMeta.data[
          chartData.datasets[0].data.length - 1
        ]._model.radius = 0;
        */

        // Render the chart.

        newchart.render();
        setLineChart({
          ...lineChart,
          chart: newchart
        })

      } catch (error) {
        console.error(error)
      }
    })();
  }, [chartData]);


  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h5 className="m-0">{title}</h5>
        <h6 className="m-0">{subTitle}</h6>
      </CardHeader>
      <CardBody className="pt-0">
        {chartData ?
          <canvas
            height="120"
            ref={canvasRef}
            style={{ maxWidth: "100% !important" }}
          />
          : "NO DATA"}
      </CardBody>
    </Card>
  );


}
export default LineChart;
