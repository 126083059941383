import React, { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";

import Chart from "../../utils/chart";

const BarChart = ({ title, chartData }) => {
  const [barChart, setBarChart] = useState({
    chart: null
  })

  const canvasRef = useRef()


  const chartOptions = {
    legend: {
      position: "bottom",
      labels: {
        padding: 0,
        boxWidth: 20
      }
    },
    cutoutPercentage: 0,
    tooltips: {
      custom: false,
      mode: "index",
      position: "nearest"
    }
  }


  useEffect(() => {
    (async () => {
      try {
        if (barChart.chart != null) {
          barChart.chart.destroy();
        }

        let newchart = new Chart(canvasRef.current, {
          type: 'bar',
          data: chartData,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          },
        });

        newchart.render();
        setBarChart({
          ...barChart,
          chart: newchart
        })

      } catch (error) {
        console.error(error)
      }
    })();
  }, [chartData]);








  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <CardBody className="d-flex py-0">
        <canvas
          height="120"
          ref={canvasRef}
          className="blog-users-by-device m-auto"
        />
      </CardBody>

    </Card>
  );
}


export default BarChart;
