import React, { useRef, useEffect, useState } from "react";
import {
  Row,
  Col,
  FormSelect,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from "shards-react";

import Chart from "../../utils/chart";

const PieChart = ({ title, chartData }) => {
  const [pieChart, setPieChart] = useState({
    chart: null
  })

  const canvasRef = useRef()


  const chartOptions = {
    legend: {
      position: "bottom",
      labels: {
        padding: 2,
        boxWidth: 20
      }
    },
    cutoutPercentage: 0,
    tooltips: {
      custom: false,
      mode: "index",
      position: "nearest"
    }
  }


  useEffect(() => {
    (async () => {
      try {
        if (pieChart.chart != null) {
          pieChart.chart.destroy();
        }
        
        let newchart = new Chart(canvasRef.current, {
          type: "pie",
          data: chartData,
          options: chartOptions
        });

        newchart.render();
        setPieChart({
          ...pieChart,
          chart: newchart
        })

      } catch (error) {
        console.error(error)
      }
    })();
  }, [chartData]);








  return (
    <Card small className="h-100">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <CardBody className="d-flex py-0">
        <canvas
          height="225"
          ref={canvasRef}
          className="blog-users-by-device m-auto"
        />
      </CardBody>
      
    </Card>
  );
}


export default PieChart;
