

import React from "react";
import {
    Container,
    Row,
    Col,
    Alert,
    Button
} from "shards-react";

import PageTitle from "../components/common/PageTitle";


const logout= async (keycloak)=>{
    await keycloak.updateToken(30);
    window.location.href="https://identity.software-si.it/realms/swsi-monitoring/protocol/openid-connect/logout"
}


const DoLogout = ({ keycloak }) => {
    return (
        <div>
            <Container fluid className="px-0">
                <Alert className="mb-0">
                    <i className="fa fa-info mx-2"></i> Attenzione! Eseguendo il LOGOUT le informazioni non salvate andranno perse.
                </Alert>
            </Container>
            <Container fluid className="main-content-container px-4">
                <Row noGutters className="page-header py-4">
                    <PageTitle
                        sm="4"
                        title="Vuoi effettuare il logout?"
                        subtitle="logout"
                        className="text-sm-left"
                    />
                </Row>
                <Row>
                    <Col>
                        <Button onClick={()=>logout(keycloak)} theme="warning" className="mb-2 mr-1">
                            Logout
                        </Button>

                    </Col>
                </Row>


            </Container>
        </div>
    );
}


export default DoLogout;
