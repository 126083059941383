export default function () {
  return [   
    {
      title: "Macchine",
      htmlBefore: '<i class="material-icons">factory</i>',
      to: "/machines",
    },
    {
      title: "Variabili",
      htmlBefore: '<i class="material-icons">list</i>',
      to: "/vars",
    },
    {
      title: "Osservazioni",
      htmlBefore: '<i class="material-icons">timeline</i>',
      to: "/dashboard",
    },        
    {
      title: "Stato Generale",
      htmlBefore: '<i class="material-icons">troubleshoot</i>',
      to: "/anomaly-info",
    },
    {
      title: "Messages",
      htmlBefore: '<i class="material-icons">message</i>',
      to: "/logs",
    },
    {
      title: "Exports",
      htmlBefore: '<i class="material-icons">summarize</i>',
      to: "/exports",
    },    
    {
      title: "Playground",
      htmlBefore: '<i class="material-icons">play_arrow</i>',
      to: "/playground",
    },           
    {
      title: "Logout",
      htmlBefore: '<i class="material-icons">logout</i>',
      to: "/do-logout",
    },
  ];
}
