/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, CardHeader, FormSelect
} from "shards-react";

import { useHistory } from "react-router-dom";
import NewApp from "../components/new-app/NewApp"
import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';


const LogsView = ({ keycloak }) => {
  const [logsView, setLogs] = useState({
    logs: [],
    start_time: "-30m",
    num_msg_label: "NO DATA",
    machines: [],
    selected_machine:null
  })




  const get_data = async (start_time) => {
    try {
      let machines = await get_machines();
      let selected_machine;

      if (logsView.selected_machine == null) {
          selected_machine = machines[0].id;
      }
      else {
          selected_machine = logsView.selected_machine
      }

      await keycloak.updateToken(30);
      let url = window.env.BACKEND_URL + "logs?start_time=" + start_time+"&machine_id="+selected_machine;
      let responseFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
      let num_msg = responseFromBe.data.logs.length
      if (responseFromBe.data != null && responseFromBe.data != null) {
        setLogs({
          ...logsView,
          logs: responseFromBe.data.logs,
          num_msg_label: num_msg + " Messaggi ricevuti",
          machines:machines
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleStartTimeChange = async (event) => {
    let start_time = event.target.value
    await setLogs({
      ...logsView,
      start_time: event.target.value
    })

  }

  const handleMachineChange = async (event) => {
    await setLogs({
      ...logsView,
      selected_machine: event.target.value
    })
  }
  const get_machines = async () => {
    await keycloak.updateToken(30);
    let url = window.env.BACKEND_URL + "machines";
    let machines_response = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)
    if (machines_response.data != null) {
      return machines_response.data.machines
    }
    else {
      return []
    }


  }

  useEffect(() => {
    (async () => {
      await get_data("-30m")
    })();
  }, []);



  if (!logsView.logs) {
    return <div>Loading...</div>;
  }
  return (
    <Container fluid className="main-content-container px-8">

      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle="Data logs" className="text-sm-left" />
          </Col>
        </Row>

        <Container fluid className="main-content-container px-2">
          <Row noGutters>
            <Col lg="3" md="6" sm="12" className="mb-8">Intervallo tempo</Col>
            <Col lg="3" md="6" sm="12" className="mb-8">Macchina</Col>
          </Row>
          <Row noGutters>
            <Col lg="3" md="6" sm="12" className="mb-8">
              <FormSelect
                style={{ "minWidth": "184px", "maxHeight": "48px" }}
                onChange={handleStartTimeChange}>
                <option value="-5m">Ultimi 5 minuti</option>
                <option selected value="-30m">Ultima mezz'ora</option>
                <option value="-1h">Ultima ora</option>
                <option value="-6h">Ultime 6 ore</option>
                <option value="-12h">Ultime 12 ore</option>
                <option value="-24h">Ultime 24 ore</option>
              </FormSelect>
            </Col>
            <Col lg="3" md="6" sm="12" className="mb-8">
              <FormSelect
                style={{ "minWidth": "184px", "maxHeight": "48px" }}
                onChange={handleMachineChange}>
                {logsView.machines.map(machine => {
                  return <option value={machine.id}>{machine.name}</option>
                })}

              </FormSelect>
            </Col>
            <Col lg="3" md="6" sm="12" className="mb-8">
              <Button onClick={() => get_data(logsView.start_time)}>
                Aggiorna
              </Button>
            </Col>
          </Row>
        </Container>

        <p></p>
        <Container fluid className="main-content-container px-4">
          {/* Page Header */}

          <Row>
            <Col>
              <Card large className="mb-4">
                <CardHeader>

                  <label>  {logsView.num_msg_label}</label>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                  <table className="table mb-0">
                    <thead className="bg-light">
                      <tr>
                        <th scope="col" className="border-0">
                          Timestamp
                        </th>
                        <th scope="col" className="border-0">
                          Log
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        logsView.logs.map((entry, index) => {
                          return (
                            <tr key={index}>
                              <td>{entry.time}</td>
                              <td>{entry.data}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>

  );

}

export default LogsView;
