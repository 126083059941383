/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useState, useEffect } from "react";
import {
  Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UtilsCallBe from '../utils/utilCallBe';


const ExportsView = ({ keycloak }) => {
  const [exportsView, setExportsView] = useState({
    exports: [],
    entry_to_download: null,
    open_modal: false
  })



  const toggleModal = () => {
    setExportsView({
      ...exportsView, open_modal: false, entry_to_download: null
    })
  }

  const downloadFile = async () => {
    let request_url = window.env.BACKEND_URL + "data-export?filename=" + exportsView.entry_to_download.filename;
    try {
      let options = {
        method: "GET",
        headers: {
          'Accept': 'application/csv',
          'Content-Type': 'application/csv',
          'Authorization': 'Bearer ' + keycloak.token
        }
      }
      let rawResponse = await fetch(request_url, options);
      const blob = await rawResponse.blob();
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        exportsView.entry_to_download.filename,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      //return content;

      console.log()
      toggleModal()
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (async () => {
      try {
        await keycloak.updateToken(30);

        let url = window.env.BACKEND_URL + "exports";
        let exportsFromBe = await UtilsCallBe.callBeWithFetch(url, 'GET', keycloak.token, null)

        if (exportsFromBe.data != null ) {
          setExportsView({
            ...exportsView,
            exports: exportsFromBe.data.exports
          })
        }
      } catch (error) {
        console.error(error)
      }
    })();
  }, []);


  if (exportsView == 0) {
    return <div>Loading...</div>;
  }
  return (
    <Container fluid className="main-content-container px-8">
      <Modal open={exportsView.open_modal} toggle={toggleModal}>
        <ModalHeader>Scaricare il documento selezionato? </ModalHeader>
        <ModalBody>
          <h6>{!exportsView.entry_to_download ? "" : exportsView.entry_to_download.filename}</h6>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => downloadFile()}
            theme="info"
            className="mb-2 mr-1">
            Scarica
          </Button>
        </ModalFooter>
      </Modal>





      <Container fluid className="main-content-container px-8">
        {/* Page Header */}
        <Row noGutters className="page-header py-4">
          <Col>
            <PageTitle sm="4" title="" subtitle="Data Exports" className="text-sm-left" />
          </Col>
        </Row>


        <Container fluid className="main-content-container px-4">
          <Container fluid className="main-content-container px-4">
            {/* Page Header */}

            <Container fluid className="main-content-container px-4">
              {/* Page Header */}

              <Row>
                <Col>
                  <Card large className="mb-4">
                    <CardBody className="p-0 pb-3">
                      <table className="table mb-0">
                        <thead className="bg-light">
                          <tr>
                            <th scope="col" className="border-0">
                              Data
                            </th>
                            <th scope="col" className="border-0">
                              None File
                            </th>
                            <th scope="col" className="border-0">
                              N° Osservazioni
                            </th>
                            <th scope="col" className="border-0">
                              Download
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            exportsView.exports.map((entry, index) => {
                              return (
                                <tr key={index}>
                                  <td>{entry.export_date}</td>
                                  <td>{entry.filename}</td>
                                  <td>{entry.observations}</td>
                                  <td>
                                    <Button
                                      onClick={() => { setExportsView({ ...exportsView, entry_to_download: entry, open_modal: true }) }}
                                      theme="primary"
                                      className="mb-2 mr-1">
                                      Scarica
                                    </Button>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );

}

export default ExportsView;
